import { Box, makeStyles, Typography } from "@material-ui/core";
import { FruProButton } from "@components";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { projectId } from "../../services/firebaseConfig.json";

const useStyles = makeStyles(() => ({
  roundedBtn: {
    borderRadius: "40px !important",
    padding: "12px 26px !important",
    fontSize: "14px !important",
  },
}));

export default function Custom404() {
  const classes = useStyles();
  const router = useRouter();

  const { message } = router.query;
  const onReturnHome = () => router?.push("/");

  useEffect(() => {
    document.title = "404 | FruPro";
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {projectId === "frupro-staging" ? "🟡404 | FruPro" : "404 | FruPro"}
        </title>
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        paddingLeft="35%"
        paddingRight="35%"
        textAlign="center"
      >
        <img src={`/static/svg/ic_not_found.svg`} alt="ic_not_found" />
        <Typography gutterBottom style={{ marginTop: 16, fontSize: 24 }}>
          {message
            ? `Sorry, ${message}`
            : `Sorry, the requested URl/bad page was not found on this server.`}
        </Typography>
        <FruProButton className={classes.roundedBtn} onClick={onReturnHome}>
          BRING ME TO HOME
        </FruProButton>
      </Box>
    </>
  );
}
